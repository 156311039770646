import Vue from 'vue';
import VueRouter from 'vue-router';
import CheckOut from '@/components/CheckOut.vue'; // 引入支付组件
import ApplePay from '@/components/ApplePay.vue'; // 引入支付组件
import ApplePayTest from '@/components/ApplePayTest.vue'; // 引入支付组件

Vue.use(VueRouter);

const routes = [
    {
        path: '/checkout/:encryptedString',
        name: 'CheckOut',
        component: CheckOut,  // 绑定 CheckOut 组件
    },
    {
        path: '/applepay/:encryptedString',
        name: 'ApplePay',
        component: ApplePay,  // 绑定 CheckOut 组件
    },
    {
        path: '/applepaytest/:encryptedString',
        name: 'ApplePayTest',
        component: ApplePayTest,  // 绑定 CheckOut 组件
    },
    // 其他路由...
];

const router = new VueRouter({
    mode: 'history',
    routes
});

// 全局路由守卫设置默认标题
router.beforeEach((to, from, next) => {
    // 如果有指定 title 就使用，没有就设置默认值
    document.title = to.meta.title || 'title';
    next();
});

export default router;
