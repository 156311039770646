<template>
  <div class="container">
    <div class="cashapp-container">
      <img src="@/assets/img_pay_title.png" class="apple-title" alt="apple pay">
      
      <div class="apple-pay-desc">
        <h2>Use Apple Pay</h2>
        <p>Apple Pay is an easy and secure way to pay. Simply tap Buy with Apple Pay and complete your purchase without the need to add to a cart or fill out a form.</p>
      </div>
    </div>

    <div class="amount-container">
      <div class="order-details">
        <div class="amount-row">
          <span class="label">Amount:</span>
          <span class="value">$ {{ (amount/100).toFixed(2) }} </span>
        </div>
        <div class="divider"></div>
        <div class="order-row">
          <span class="label">Order Id:</span>
          <span class="id">{{ orderId }}</span>
        </div>
      </div>
    </div>

    <form id="payment-form" @submit.stop="handlePayment">
      <div id="payment-element"></div>
      <div id="error-message" style="text-align: center;"></div>
    </form>

    <!--<div class="apple-pay-btn" @click.stop="handlePayment">
      <img src="@/assets/btn_apple.png" class="apple-logo" alt="apple pay">
    </div>-->
  </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';
import apiClient,  {API_URL_CREATE_ORDER,API_URL_CREATE_PAYMENT}  from '@/services/api'; // 引入封装好的 HTTP 请求

export default {
  data() {
    return {
      stripe: null,
      clientSecret: null,
      elements: null,
      amount: null,  // 从 URL 查询参数中获取传入的 amount
      encryptedString: this.$route.params.encryptedString,  // 从 URL 获取加密串
      orderId: null, // 从 URL 查询参数中获取传入的 orderId
      payType: null,
      errorMsg: null,
      returnUrl: null,
      wayCode: null,
      platformWebsite: null,
      randomNo: null
    };
  },
  async mounted() {
    document.title = 'Payment Center';
    try {
      const response = await apiClient.post(API_URL_CREATE_PAYMENT, {
        encryptedString: this.encryptedString
      });
      this.clientSecret = response.data.clientSecret;
      this.amount = response.data.amount;
      this.orderId = response.data.orderId;
      this.wayCode = response.data.wayCode;
      this.platformWebsite = response.data.platformWebsite;
      this.payType = response.data.payType;
      this.errorMsg = response.data.errorMsg;
      this.returnUrl = response.data.returnUrl;
      this.randomNo = Math.random().toString(36).slice(-8);
      if(this.errorMsg === ''){
      this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);
      //locale: "en" // 设置默认显示语种   en 英文 cn 中文 auto 自动获取语种
      const elements = this.stripe.elements({ locale: "en",clientSecret: this.clientSecret, loader: 'never' });
      this.elements = elements;
      //如果是苹果pay
      if(this.wayCode === 'APPLE_PAY'){
          const clientSecret = this.clientSecret;
          const expressCheckoutOptions = {
            buttonHeight: 50,
            buttonTheme: {
              applePay: 'white-outline'
            }
          }
          const paymentElement = elements.create("expressCheckout", {
                  mode: "payment",
                  paymentMethodType: "card",
                  clientSecret,
                  expressCheckoutOptions,
                  paymentMethods:{applePay:"always"},
                  supportedPaymentMethods: ["apple_pay"], // 仅支持 Apple Pay
          });
          paymentElement.mount('#payment-element');
          paymentElement.on("confirm", async (event) => {
            console.log(event)
            const { error } = await this.stripe.confirmPayment({
              elements,
              confirmParams: {
                return_url: this.returnUrl
              },
            });
            if (error) {
              console.error(error)
              document.getElementById('error-message').textContent = error;
              this.sendOrderInfo(this.orderId,this.randomNo + ' APPLE_wayCode3 confirm error',error);
            }
          });
        }
      }else{
          document.getElementById('error-message').textContent = this.errorMsg;
      }
    } catch (error) {
      console.error('error', error);
      document.getElementById('error-message').textContent = error;
      this.sendOrderInfo(this.orderId,this.randomNo + ' auto payment error',error);
    }
  },
  methods: {
    async sendOrderInfo(orderId,submitType,errMsg) {
      try {
        apiClient.post(API_URL_CREATE_ORDER, {
          userAgent: navigator.userAgent,
          orderId: orderId,
          submitType: submitType,
          errMsg: errMsg
        });
      } catch (error) {
        console.error('error:', error);
      }
    },
    async handlePayment() {

    }
  },
};
</script>

<style scoped>
/* 新增外层容器 */
.container {
  max-width: 100%;
  min-height: 100vh;
  background-color: #0000;
}

.cashapp-container {
  max-width: 400px;
  margin: 20px auto 0px auto;
  padding: 30px 25px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}

.amount-container {
  max-width: 450px;
  margin: 0 auto;
  padding: 0px 0px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}


/* 按钮样式 */
.apple-pay-btn {
  max-width: 450px;
  margin:20px auto;
  padding: 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 56px;
  background: #0A0A0A;
  border-radius: 8px;
  cursor: pointer;
  transition: opacity 0.2s;
}
.apple-pay-btn:hover {
  opacity: 0.9;
}

/* 居中文字 */
.apple-pay-desc {
  text-align: center;
}
.apple-pay-desc h2 {
  font-size: 18px;
  margin: 0 0 12px 0;
  color: #0A0A0A;
}
.apple-pay-desc p {
  color: #666;
  line-height: 1.5;
  margin: 8px 0;
  font-size: 14px;
  color: #929292;
}


/* 订单信息样式 */
.order-details {
  background: #fff;
  border-radius: 8px;
  margin:7px 0;
  color: #929292;
  padding: 13px 0px;
}

.amount-row, .order-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 3px 15px;
}

.label {
  color: #929292;
  font-size: 16px;
}

.value {
  color: #0A0A0A;
  font-weight: 600;
  font-size: 18px;
  padding: 0px 5px;
}

.id {
  color: #929292;
  font-size: 14px;
  padding: 0px 5px;
}

.divider {
  height: 1px;
  background-color: #eee;
  margin: 12px 0;
}

.apple-logo {
  width: auto;
  height: 24px;
}

/* 错误信息样式 */
#error-message {
  color: red;
  margin-top: 10px;
}

/* 标题图片 */
.apple-title {
  display: block;
  margin: 0 auto 20px;
  width: 120px;
}
</style>